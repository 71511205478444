.search-form[data-v-a119b156] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-a119b156] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-a119b156] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-a119b156] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
[data-v-a119b156] .el-table {
  height: calc(100vh - 274px) !important;
}
[data-v-a119b156] .el-table thead tr,
.el-table thead tr th[data-v-a119b156] {
  background: #fafafa !important;
}
[data-v-a119b156] .el-table--border,
.el-table--group[data-v-a119b156] {
  border-color: #cccccc !important;
}
[data-v-a119b156] .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf[data-v-a119b156] {
  border-color: #cccccc !important;
}
[data-v-a119b156] .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed[data-v-a119b156] {
  border-color: #cccccc !important;
}
[data-v-a119b156] .el-table--border th.el-table__cell,
.el-table__fixed-right-patch[data-v-a119b156] {
  border-color: #cccccc !important;
}
[data-v-a119b156] .el-table__fixed-right::before {
  background-color: #ffffff !important;
}
[data-v-a119b156] .el-table__fixed::before {
  background-color: #ffffff !important;
}
[data-v-a119b156] .el-table--border::after,
.el-table--group[data-v-a119b156]::after,
.el-table[data-v-a119b156]::before {
  background-color: #cccccc !important;
}
[data-v-a119b156] .el-table--border {
  border-bottom: 1px solid #cccccc;
}
[data-v-a119b156] .el-table--mini .el-table__cell {
  padding: 0 !important;
}
[data-v-a119b156] .el-button + .el-button {
  margin-left: 0px;
}
